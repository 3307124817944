// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.29.3
// source: sort_order.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "tanagra";

export interface SortOrder {
  attribute: string;
  direction: SortOrder_Direction;
}

export enum SortOrder_Direction {
  SORT_ORDER_DIRECTION_UNKNOWN = 0,
  SORT_ORDER_DIRECTION_ASCENDING = 1,
  SORT_ORDER_DIRECTION_DESCENDING = 2,
  UNRECOGNIZED = -1,
}

export function sortOrder_DirectionFromJSON(object: any): SortOrder_Direction {
  switch (object) {
    case 0:
    case "SORT_ORDER_DIRECTION_UNKNOWN":
      return SortOrder_Direction.SORT_ORDER_DIRECTION_UNKNOWN;
    case 1:
    case "SORT_ORDER_DIRECTION_ASCENDING":
      return SortOrder_Direction.SORT_ORDER_DIRECTION_ASCENDING;
    case 2:
    case "SORT_ORDER_DIRECTION_DESCENDING":
      return SortOrder_Direction.SORT_ORDER_DIRECTION_DESCENDING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SortOrder_Direction.UNRECOGNIZED;
  }
}

export function sortOrder_DirectionToJSON(object: SortOrder_Direction): string {
  switch (object) {
    case SortOrder_Direction.SORT_ORDER_DIRECTION_UNKNOWN:
      return "SORT_ORDER_DIRECTION_UNKNOWN";
    case SortOrder_Direction.SORT_ORDER_DIRECTION_ASCENDING:
      return "SORT_ORDER_DIRECTION_ASCENDING";
    case SortOrder_Direction.SORT_ORDER_DIRECTION_DESCENDING:
      return "SORT_ORDER_DIRECTION_DESCENDING";
    case SortOrder_Direction.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseSortOrder(): SortOrder {
  return { attribute: "", direction: 0 };
}

export const SortOrder = {
  encode(message: SortOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.attribute !== "") {
      writer.uint32(10).string(message.attribute);
    }
    if (message.direction !== 0) {
      writer.uint32(16).int32(message.direction);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SortOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSortOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.attribute = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.direction = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SortOrder {
    return {
      attribute: isSet(object.attribute) ? globalThis.String(object.attribute) : "",
      direction: isSet(object.direction) ? sortOrder_DirectionFromJSON(object.direction) : 0,
    };
  },

  toJSON(message: SortOrder): unknown {
    const obj: any = {};
    if (message.attribute !== "") {
      obj.attribute = message.attribute;
    }
    if (message.direction !== 0) {
      obj.direction = sortOrder_DirectionToJSON(message.direction);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SortOrder>, I>>(base?: I): SortOrder {
    return SortOrder.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SortOrder>, I>>(object: I): SortOrder {
    const message = createBaseSortOrder();
    message.attribute = object.attribute ?? "";
    message.direction = object.direction ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | bigint | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
