// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.29.3
// source: criteriaselector/configschema/entity_group.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Column } from "../../column";
import { SortOrder } from "../../sort_order";
import { ValueConfig } from "../value_config";

export const protobufPackage = "tanagra.configschema";

/**
 * A criteria based on one or more entity groups. This allows the selection of
 * primary entities which are related to one or more of another entity which
 * match certain characteristics (e.g. people related to condition_occurrences
 * which have condition_name of "Diabetes").
 */
export interface EntityGroup {
  /** Columns displayed in the list view. */
  columns: Column[];
  /** Columns displayed in the hierarchy view. */
  hierarchyColumns: Column[];
  /**
   * This has been replaced by nameAttribute for determining stored names. Now
   * this only determines which is the primary column for checkboxes, etc.
   */
  nameColumnIndex: number;
  /**
   * Entity groups where the related entity is what is selected (e.g. condition
   * when filtering condition_occurrences).
   */
  classificationEntityGroups: EntityGroup_EntityGroupConfig[];
  /**
   * Entity groups where the related entity is not what is selected (e.g. brands
   * when filtering ingredients or genotyping platforms when filtering people).
   */
  groupingEntityGroups: EntityGroup_EntityGroupConfig[];
  /**
   * Whether a single click selects a value or multiple values can be selected
   * and then confirmed.
   */
  multiSelect: boolean;
  /**
   * Optional configuration of a categorical or numeric value associated with
   * the selection (e.g. a measurement value). Applied to the entire selection
   * so generally not compatible with multi_select. Currently only one is
   * supported.
   */
  valueConfigs: ValueConfig[];
  /**
   * The sort order to use in the list view, or in hierarchies where no sort
   * order has been specified.
   */
  defaultSort:
    | SortOrder
    | undefined;
  /**
   * Number of values to display in the list view for each entity group.
   * Otherwise, a default value is applied.
   */
  limit?:
    | number
    | undefined;
  /**
   * The attribute used to name selections if not the first column. This can be
   * used to include extra context with the selected values that's not visible
   * in the table view.
   */
  nameAttribute?:
    | string
    | undefined;
  /**
   * Optional attributes to search when adding criteria by code. It's
   * recommended to enable multi_select when using codeAttributes because
   * multiple codes can be added at the same time which forces the criteria into
   * multi_select mode regardless of the setting.
   */
  codeAttributes: string[];
}

/**
 * Multiple entity groups can be shown within the same criteria. Typically
 * they would filter over the same entity, or at least very similar entities,
 * since much of the configuration (e.g. columns) is shared. Both types of
 * configs can be combined though they are displayed separately.
 */
export interface EntityGroup_EntityGroupConfig {
  /** The id of the entity group. */
  id: string;
  /**
   * The sort order applied to this entity group when displayed in the
   * hierarchy view.
   */
  sortOrder: SortOrder | undefined;
}

function createBaseEntityGroup(): EntityGroup {
  return {
    columns: [],
    hierarchyColumns: [],
    nameColumnIndex: 0,
    classificationEntityGroups: [],
    groupingEntityGroups: [],
    multiSelect: false,
    valueConfigs: [],
    defaultSort: undefined,
    limit: undefined,
    nameAttribute: undefined,
    codeAttributes: [],
  };
}

export const EntityGroup = {
  encode(message: EntityGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.columns) {
      Column.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.hierarchyColumns) {
      Column.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.nameColumnIndex !== 0) {
      writer.uint32(24).int32(message.nameColumnIndex);
    }
    for (const v of message.classificationEntityGroups) {
      EntityGroup_EntityGroupConfig.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.groupingEntityGroups) {
      EntityGroup_EntityGroupConfig.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.multiSelect !== false) {
      writer.uint32(48).bool(message.multiSelect);
    }
    for (const v of message.valueConfigs) {
      ValueConfig.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.defaultSort !== undefined) {
      SortOrder.encode(message.defaultSort, writer.uint32(66).fork()).ldelim();
    }
    if (message.limit !== undefined) {
      writer.uint32(72).int32(message.limit);
    }
    if (message.nameAttribute !== undefined) {
      writer.uint32(82).string(message.nameAttribute);
    }
    for (const v of message.codeAttributes) {
      writer.uint32(90).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntityGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntityGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.columns.push(Column.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.hierarchyColumns.push(Column.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.nameColumnIndex = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.classificationEntityGroups.push(EntityGroup_EntityGroupConfig.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.groupingEntityGroups.push(EntityGroup_EntityGroupConfig.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.multiSelect = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.valueConfigs.push(ValueConfig.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.defaultSort = SortOrder.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.limit = reader.int32();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.nameAttribute = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.codeAttributes.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntityGroup {
    return {
      columns: globalThis.Array.isArray(object?.columns) ? object.columns.map((e: any) => Column.fromJSON(e)) : [],
      hierarchyColumns: globalThis.Array.isArray(object?.hierarchyColumns)
        ? object.hierarchyColumns.map((e: any) => Column.fromJSON(e))
        : [],
      nameColumnIndex: isSet(object.nameColumnIndex) ? globalThis.Number(object.nameColumnIndex) : 0,
      classificationEntityGroups: globalThis.Array.isArray(object?.classificationEntityGroups)
        ? object.classificationEntityGroups.map((e: any) => EntityGroup_EntityGroupConfig.fromJSON(e))
        : [],
      groupingEntityGroups: globalThis.Array.isArray(object?.groupingEntityGroups)
        ? object.groupingEntityGroups.map((e: any) => EntityGroup_EntityGroupConfig.fromJSON(e))
        : [],
      multiSelect: isSet(object.multiSelect) ? globalThis.Boolean(object.multiSelect) : false,
      valueConfigs: globalThis.Array.isArray(object?.valueConfigs)
        ? object.valueConfigs.map((e: any) => ValueConfig.fromJSON(e))
        : [],
      defaultSort: isSet(object.defaultSort) ? SortOrder.fromJSON(object.defaultSort) : undefined,
      limit: isSet(object.limit) ? globalThis.Number(object.limit) : undefined,
      nameAttribute: isSet(object.nameAttribute) ? globalThis.String(object.nameAttribute) : undefined,
      codeAttributes: globalThis.Array.isArray(object?.codeAttributes)
        ? object.codeAttributes.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: EntityGroup): unknown {
    const obj: any = {};
    if (message.columns?.length) {
      obj.columns = message.columns.map((e) => Column.toJSON(e));
    }
    if (message.hierarchyColumns?.length) {
      obj.hierarchyColumns = message.hierarchyColumns.map((e) => Column.toJSON(e));
    }
    if (message.nameColumnIndex !== 0) {
      obj.nameColumnIndex = Math.round(message.nameColumnIndex);
    }
    if (message.classificationEntityGroups?.length) {
      obj.classificationEntityGroups = message.classificationEntityGroups.map((e) =>
        EntityGroup_EntityGroupConfig.toJSON(e)
      );
    }
    if (message.groupingEntityGroups?.length) {
      obj.groupingEntityGroups = message.groupingEntityGroups.map((e) => EntityGroup_EntityGroupConfig.toJSON(e));
    }
    if (message.multiSelect !== false) {
      obj.multiSelect = message.multiSelect;
    }
    if (message.valueConfigs?.length) {
      obj.valueConfigs = message.valueConfigs.map((e) => ValueConfig.toJSON(e));
    }
    if (message.defaultSort !== undefined) {
      obj.defaultSort = SortOrder.toJSON(message.defaultSort);
    }
    if (message.limit !== undefined) {
      obj.limit = Math.round(message.limit);
    }
    if (message.nameAttribute !== undefined) {
      obj.nameAttribute = message.nameAttribute;
    }
    if (message.codeAttributes?.length) {
      obj.codeAttributes = message.codeAttributes;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EntityGroup>, I>>(base?: I): EntityGroup {
    return EntityGroup.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntityGroup>, I>>(object: I): EntityGroup {
    const message = createBaseEntityGroup();
    message.columns = object.columns?.map((e) => Column.fromPartial(e)) || [];
    message.hierarchyColumns = object.hierarchyColumns?.map((e) => Column.fromPartial(e)) || [];
    message.nameColumnIndex = object.nameColumnIndex ?? 0;
    message.classificationEntityGroups =
      object.classificationEntityGroups?.map((e) => EntityGroup_EntityGroupConfig.fromPartial(e)) || [];
    message.groupingEntityGroups =
      object.groupingEntityGroups?.map((e) => EntityGroup_EntityGroupConfig.fromPartial(e)) || [];
    message.multiSelect = object.multiSelect ?? false;
    message.valueConfigs = object.valueConfigs?.map((e) => ValueConfig.fromPartial(e)) || [];
    message.defaultSort = (object.defaultSort !== undefined && object.defaultSort !== null)
      ? SortOrder.fromPartial(object.defaultSort)
      : undefined;
    message.limit = object.limit ?? undefined;
    message.nameAttribute = object.nameAttribute ?? undefined;
    message.codeAttributes = object.codeAttributes?.map((e) => e) || [];
    return message;
  },
};

function createBaseEntityGroup_EntityGroupConfig(): EntityGroup_EntityGroupConfig {
  return { id: "", sortOrder: undefined };
}

export const EntityGroup_EntityGroupConfig = {
  encode(message: EntityGroup_EntityGroupConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.sortOrder !== undefined) {
      SortOrder.encode(message.sortOrder, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntityGroup_EntityGroupConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntityGroup_EntityGroupConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sortOrder = SortOrder.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntityGroup_EntityGroupConfig {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      sortOrder: isSet(object.sortOrder) ? SortOrder.fromJSON(object.sortOrder) : undefined,
    };
  },

  toJSON(message: EntityGroup_EntityGroupConfig): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.sortOrder !== undefined) {
      obj.sortOrder = SortOrder.toJSON(message.sortOrder);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EntityGroup_EntityGroupConfig>, I>>(base?: I): EntityGroup_EntityGroupConfig {
    return EntityGroup_EntityGroupConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntityGroup_EntityGroupConfig>, I>>(
    object: I,
  ): EntityGroup_EntityGroupConfig {
    const message = createBaseEntityGroup_EntityGroupConfig();
    message.id = object.id ?? "";
    message.sortOrder = (object.sortOrder !== undefined && object.sortOrder !== null)
      ? SortOrder.fromPartial(object.sortOrder)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | bigint | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
