// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.29.3
// source: criteriaselector/dataschema/attribute.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Value } from "../../value";
import { DataRange } from "../data_range";

export const protobufPackage = "tanagra.dataschema";

/** Data for an attribute criteria is a list of categorical values or ranges. */
export interface Attribute {
  selected: Attribute_Selection[];
  /**
   * Contains one or more numeric ranges when referencing a numeric value (e.g.
   * age).
   */
  dataRanges: DataRange[];
}

/** A single selected categorical value (e.g. {value: 1234, name: "Diabetes"}. */
export interface Attribute_Selection {
  /** The value used to build queries. */
  value:
    | Value
    | undefined;
  /**
   * The visible name for the selection. This is stored to avoid extra lookups
   * when rendering.
   */
  name: string;
}

function createBaseAttribute(): Attribute {
  return { selected: [], dataRanges: [] };
}

export const Attribute = {
  encode(message: Attribute, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.selected) {
      Attribute_Selection.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.dataRanges) {
      DataRange.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Attribute {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttribute();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.selected.push(Attribute_Selection.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dataRanges.push(DataRange.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Attribute {
    return {
      selected: globalThis.Array.isArray(object?.selected)
        ? object.selected.map((e: any) => Attribute_Selection.fromJSON(e))
        : [],
      dataRanges: globalThis.Array.isArray(object?.dataRanges)
        ? object.dataRanges.map((e: any) => DataRange.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Attribute): unknown {
    const obj: any = {};
    if (message.selected?.length) {
      obj.selected = message.selected.map((e) => Attribute_Selection.toJSON(e));
    }
    if (message.dataRanges?.length) {
      obj.dataRanges = message.dataRanges.map((e) => DataRange.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Attribute>, I>>(base?: I): Attribute {
    return Attribute.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Attribute>, I>>(object: I): Attribute {
    const message = createBaseAttribute();
    message.selected = object.selected?.map((e) => Attribute_Selection.fromPartial(e)) || [];
    message.dataRanges = object.dataRanges?.map((e) => DataRange.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAttribute_Selection(): Attribute_Selection {
  return { value: undefined, name: "" };
}

export const Attribute_Selection = {
  encode(message: Attribute_Selection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== undefined) {
      Value.encode(message.value, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Attribute_Selection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttribute_Selection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.value = Value.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Attribute_Selection {
    return {
      value: isSet(object.value) ? Value.fromJSON(object.value) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: Attribute_Selection): unknown {
    const obj: any = {};
    if (message.value !== undefined) {
      obj.value = Value.toJSON(message.value);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Attribute_Selection>, I>>(base?: I): Attribute_Selection {
    return Attribute_Selection.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Attribute_Selection>, I>>(object: I): Attribute_Selection {
    const message = createBaseAttribute_Selection();
    message.value = (object.value !== undefined && object.value !== null) ? Value.fromPartial(object.value) : undefined;
    message.name = object.name ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | bigint | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
