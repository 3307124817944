// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.29.3
// source: criteriaselector/dataschema/multi_attribute.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { ValueData } from "../value_data";

export const protobufPackage = "tanagra.dataschema";

/**
 * Data for a multi attribute criteria is a list of categorical or numeric
 * values.
 */
export interface MultiAttribute {
  valueData: ValueData[];
}

function createBaseMultiAttribute(): MultiAttribute {
  return { valueData: [] };
}

export const MultiAttribute = {
  encode(message: MultiAttribute, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.valueData) {
      ValueData.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MultiAttribute {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMultiAttribute();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.valueData.push(ValueData.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MultiAttribute {
    return {
      valueData: globalThis.Array.isArray(object?.valueData)
        ? object.valueData.map((e: any) => ValueData.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MultiAttribute): unknown {
    const obj: any = {};
    if (message.valueData?.length) {
      obj.valueData = message.valueData.map((e) => ValueData.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MultiAttribute>, I>>(base?: I): MultiAttribute {
    return MultiAttribute.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MultiAttribute>, I>>(object: I): MultiAttribute {
    const message = createBaseMultiAttribute();
    message.valueData = object.valueData?.map((e) => ValueData.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | bigint | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };
