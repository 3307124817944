/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderByDirection,
    OrderByDirectionFromJSON,
    OrderByDirectionFromJSONTyped,
    OrderByDirectionToJSON,
} from './';

/**
 * Count output entity instances for a cohort filter
 * @export
 * @interface CohortCountQuery
 */
export interface CohortCountQuery {
    /**
     * 
     * @type {string}
     * @memberof CohortCountQuery
     */
    criteriaGroupSectionId?: string;
    /**
     * 
     * @type {string}
     * @memberof CohortCountQuery
     */
    criteriaGroupId?: string;
    /**
     * Entity to count. Defaults to primary entity.
     * @type {string}
     * @memberof CohortCountQuery
     */
    entity?: string;
    /**
     * Attribute to count (e.g. person_id will return counts for the number of distinct person_ids 
     * per group by combination). Defaults to id attribute.
     * @type {string}
     * @memberof CohortCountQuery
     */
    countDistinctAttribute?: string;
    /**
     * Attributes to group by. One count will be returned for each possible combination of attribute values 
     * (e.g. [gender, hair_color] will return counts for man+red, man+black, woman+red, woman+black).
     * @type {Array<string>}
     * @memberof CohortCountQuery
     */
    groupByAttributes: Array<string>;
    /**
     * 
     * @type {OrderByDirection}
     * @memberof CohortCountQuery
     */
    orderByDirection?: OrderByDirection;
    /**
     * Maximum number of results to return. Defaults to 50.
     * @type {number}
     * @memberof CohortCountQuery
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof CohortCountQuery
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof CohortCountQuery
     */
    pageMarker?: string;
}

export function CohortCountQueryFromJSON(json: any): CohortCountQuery {
    return CohortCountQueryFromJSONTyped(json, false);
}

export function CohortCountQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CohortCountQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'criteriaGroupSectionId': !exists(json, 'criteriaGroupSectionId') ? undefined : json['criteriaGroupSectionId'],
        'criteriaGroupId': !exists(json, 'criteriaGroupId') ? undefined : json['criteriaGroupId'],
        'entity': !exists(json, 'entity') ? undefined : json['entity'],
        'countDistinctAttribute': !exists(json, 'countDistinctAttribute') ? undefined : json['countDistinctAttribute'],
        'groupByAttributes': json['groupByAttributes'],
        'orderByDirection': !exists(json, 'orderByDirection') ? undefined : OrderByDirectionFromJSON(json['orderByDirection']),
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'pageMarker': !exists(json, 'pageMarker') ? undefined : json['pageMarker'],
    };
}

export function CohortCountQueryToJSON(value?: CohortCountQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'criteriaGroupSectionId': value.criteriaGroupSectionId,
        'criteriaGroupId': value.criteriaGroupId,
        'entity': value.entity,
        'countDistinctAttribute': value.countDistinctAttribute,
        'groupByAttributes': value.groupByAttributes,
        'orderByDirection': OrderByDirectionToJSON(value.orderByDirection),
        'limit': value.limit,
        'pageSize': value.pageSize,
        'pageMarker': value.pageMarker,
    };
}


