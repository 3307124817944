/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExportPreviewRequest
 */
export interface ExportPreviewRequest {
    /**
     * 
     * @type {string}
     * @memberof ExportPreviewRequest
     */
    study: string;
    /**
     * List of cohort ids
     * @type {Array<string>}
     * @memberof ExportPreviewRequest
     */
    cohorts: Array<string>;
    /**
     * List of feature set ids
     * @type {Array<string>}
     * @memberof ExportPreviewRequest
     */
    featureSets: Array<string>;
    /**
     * Maximum number of results to return. Defaults to 50.
     * @type {number}
     * @memberof ExportPreviewRequest
     */
    limit?: number;
    /**
     * When true, ignore the selected attributes in the data feature set definitions and include all
     * attributes for each output entity.
     * @type {boolean}
     * @memberof ExportPreviewRequest
     */
    includeAllAttributes?: boolean;
}

export function ExportPreviewRequestFromJSON(json: any): ExportPreviewRequest {
    return ExportPreviewRequestFromJSONTyped(json, false);
}

export function ExportPreviewRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportPreviewRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'study': json['study'],
        'cohorts': json['cohorts'],
        'featureSets': json['featureSets'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'includeAllAttributes': !exists(json, 'includeAllAttributes') ? undefined : json['includeAllAttributes'],
    };
}

export function ExportPreviewRequestToJSON(value?: ExportPreviewRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'study': value.study,
        'cohorts': value.cohorts,
        'featureSets': value.featureSets,
        'limit': value.limit,
        'includeAllAttributes': value.includeAllAttributes,
    };
}


