/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderByDirection,
    OrderByDirectionFromJSON,
    OrderByDirectionFromJSONTyped,
    OrderByDirectionToJSON,
} from './';

/**
 * Attribute or annotation and the direction
 * @export
 * @interface ReviewQueryOrderBys
 */
export interface ReviewQueryOrderBys {
    /**
     * Name of the attribute
     * @type {string}
     * @memberof ReviewQueryOrderBys
     */
    attribute?: string;
    /**
     * ID of the annotation, immutable
     * @type {string}
     * @memberof ReviewQueryOrderBys
     */
    annotation?: string;
    /**
     * 
     * @type {OrderByDirection}
     * @memberof ReviewQueryOrderBys
     */
    direction?: OrderByDirection;
}

export function ReviewQueryOrderBysFromJSON(json: any): ReviewQueryOrderBys {
    return ReviewQueryOrderBysFromJSONTyped(json, false);
}

export function ReviewQueryOrderBysFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewQueryOrderBys {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attribute': !exists(json, 'attribute') ? undefined : json['attribute'],
        'annotation': !exists(json, 'annotation') ? undefined : json['annotation'],
        'direction': !exists(json, 'direction') ? undefined : OrderByDirectionFromJSON(json['direction']),
    };
}

export function ReviewQueryOrderBysToJSON(value?: ReviewQueryOrderBys | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attribute': value.attribute,
        'annotation': value.annotation,
        'direction': OrderByDirectionToJSON(value.direction),
    };
}


