// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.29.3
// source: criteriaselector/key.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "tanagra";

export interface Key {
  stringKey?: string | undefined;
  int64Key?: bigint | undefined;
}

function createBaseKey(): Key {
  return { stringKey: undefined, int64Key: undefined };
}

export const Key = {
  encode(message: Key, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.stringKey !== undefined) {
      writer.uint32(10).string(message.stringKey);
    }
    if (message.int64Key !== undefined) {
      if (BigInt.asIntN(64, message.int64Key) !== message.int64Key) {
        throw new globalThis.Error("value provided for field message.int64Key of type int64 too large");
      }
      writer.uint32(16).int64(message.int64Key.toString());
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Key {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKey();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.stringKey = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.int64Key = longToBigint(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Key {
    return {
      stringKey: isSet(object.stringKey) ? globalThis.String(object.stringKey) : undefined,
      int64Key: isSet(object.int64Key) ? BigInt(object.int64Key) : undefined,
    };
  },

  toJSON(message: Key): unknown {
    const obj: any = {};
    if (message.stringKey !== undefined) {
      obj.stringKey = message.stringKey;
    }
    if (message.int64Key !== undefined) {
      obj.int64Key = message.int64Key.toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Key>, I>>(base?: I): Key {
    return Key.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Key>, I>>(object: I): Key {
    const message = createBaseKey();
    message.stringKey = object.stringKey ?? undefined;
    message.int64Key = object.int64Key ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | bigint | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToBigint(long: Long) {
  return BigInt(long.toString());
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
