/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Filter,
    FilterFromJSON,
    FilterFromJSONTyped,
    FilterToJSON,
    QueryIncludeHierarchyFields,
    QueryIncludeHierarchyFieldsFromJSON,
    QueryIncludeHierarchyFieldsFromJSONTyped,
    QueryIncludeHierarchyFieldsToJSON,
    QueryIncludeRelationshipFields,
    QueryIncludeRelationshipFieldsFromJSON,
    QueryIncludeRelationshipFieldsFromJSONTyped,
    QueryIncludeRelationshipFieldsToJSON,
    QueryOrderBys,
    QueryOrderBysFromJSON,
    QueryOrderBysFromJSONTyped,
    QueryOrderBysToJSON,
} from './';

/**
 * Query for entity instances
 * @export
 * @interface Query
 */
export interface Query {
    /**
     * Attributes to include in the returned instances
     * @type {Array<string>}
     * @memberof Query
     */
    includeAttributes?: Array<string>;
    /**
     * 
     * @type {QueryIncludeHierarchyFields}
     * @memberof Query
     */
    includeHierarchyFields?: QueryIncludeHierarchyFields;
    /**
     * Relationship (count) fields to include in the returned instances.
     * @type {Array<QueryIncludeRelationshipFields>}
     * @memberof Query
     */
    includeRelationshipFields?: Array<QueryIncludeRelationshipFields>;
    /**
     * 
     * @type {Filter}
     * @memberof Query
     */
    filter?: Filter | null;
    /**
     * Attributes and direction to order the results by
     * @type {Array<QueryOrderBys>}
     * @memberof Query
     */
    orderBys?: Array<QueryOrderBys>;
    /**
     * Maximum number of results to return. Defaults to 50.
     * @type {number}
     * @memberof Query
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof Query
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof Query
     */
    pageMarker?: string;
}

export function QueryFromJSON(json: any): Query {
    return QueryFromJSONTyped(json, false);
}

export function QueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Query {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'includeAttributes': !exists(json, 'includeAttributes') ? undefined : json['includeAttributes'],
        'includeHierarchyFields': !exists(json, 'includeHierarchyFields') ? undefined : QueryIncludeHierarchyFieldsFromJSON(json['includeHierarchyFields']),
        'includeRelationshipFields': !exists(json, 'includeRelationshipFields') ? undefined : ((json['includeRelationshipFields'] as Array<any>).map(QueryIncludeRelationshipFieldsFromJSON)),
        'filter': !exists(json, 'filter') ? undefined : FilterFromJSON(json['filter']),
        'orderBys': !exists(json, 'orderBys') ? undefined : ((json['orderBys'] as Array<any>).map(QueryOrderBysFromJSON)),
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'pageMarker': !exists(json, 'pageMarker') ? undefined : json['pageMarker'],
    };
}

export function QueryToJSON(value?: Query | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'includeAttributes': value.includeAttributes,
        'includeHierarchyFields': QueryIncludeHierarchyFieldsToJSON(value.includeHierarchyFields),
        'includeRelationshipFields': value.includeRelationshipFields === undefined ? undefined : ((value.includeRelationshipFields as Array<any>).map(QueryIncludeRelationshipFieldsToJSON)),
        'filter': FilterToJSON(value.filter),
        'orderBys': value.orderBys === undefined ? undefined : ((value.orderBys as Array<any>).map(QueryOrderBysToJSON)),
        'limit': value.limit,
        'pageSize': value.pageSize,
        'pageMarker': value.pageMarker,
    };
}


